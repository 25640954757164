import { useContext } from 'react'
import PropTypes from 'prop-types'

import { combineName, finalInactiveSensors } from '../../../../shared/format'
import { correctMinMax } from '../../../../helper/ShowData'

import { SystemContext } from '../../../../shared/context'

const BackButton = ({ device }) => {
	const { systemJson } = useContext(SystemContext)
	const attributes = device?.attributes || null

	const targetThreshold = systemJson.temperatureThresholds.find(
		({ place }) => place === attributes?.installation_place
	)

	const correctMinMaxValue = correctMinMax(targetThreshold)
	const isInactiveSensors = finalInactiveSensors(
		systemJson.temperatureThresholds,
		attributes
	)

	const targetString = correctMinMaxValue.target.string
	const inactiveAlarming = 'Inaktive Alarmüberwachung'

	return (
		<div className="flex flex-col justify-center h-full ml-1">
			<div className="max-w-xs overflow-hidden font-semibold truncate overflow-ellipsis">
				{combineName(attributes)}
			</div>
			<div className="flex items-center max-w-xs italic text-xss">
				<span>{targetString}</span>
			</div>
			{isInactiveSensors && (
				<div className="flex items-center max-w-xs italic text-xss">
					<span>{inactiveAlarming}</span>
				</div>
			)}
		</div>
	)
}

BackButton.propTypes = {
	device: PropTypes.shape({
		attributes: PropTypes.object.isRequired,
	}).isRequired,
}

export default BackButton
